<template>
  <div class="result-card">
    <template v-if="isSuccess">
      <p class="has-text-weight-bold is-size-4 mb-1 result-title">
        {{
          isMortgage
            ? $t("af:mortgage_application.offline_result.success.title")
            : $t("af:application.offline_result.success.title")
        }}
      </p>
      <p class="mb-5 result-description">
        {{
          isMortgage
            ? $t("af:mortgage_application.offline_result.success.subtitle")
            : $t("af:application.offline_result.success.subtitle")
        }}
      </p>
      <application-template
        v-if="isMortgage"
        template="MORTGAGE_OFFLINE_APPLICATION_SUCCESS"
      ></application-template>
      <application-template
        v-else
        template="OFFLINE_APPLICATION_SUCCESS"
      ></application-template>
    </template>
    <template v-else>
      <p class="has-text-weight-bold is-size-4 mb-1 result-title">
        {{
          isMortgage
            ? $t("af:mortgage_application.offline_result.failed.title")
            : $t("af:application.offline_result.failed.title")
        }}
      </p>
      <p class="mb-5 result-description">
        {{
          isMortgage
            ? $t("af:mortgage_application.offline_result.failed.subtitle")
            : $t("af:application.offline_result.failed.subtitle")
        }}
      </p>
      <application-template
        v-if="isMortgage"
        template="MORTGAGE_OFFLINE_APPLICATION_FAILURE"
      ></application-template>
      <application-template
        v-else
        template="OFFLINE_APPLICATION_FAILURE"
      ></application-template>
    </template>

    <b-field class="mt-6">
      <b-button
        v-if="isMortgage"
        id="mortgageFlowResultButtonFinishApplication"
        type="is-success"
        size="is-large"
        :label="$t('af:mortgage_application.result.button.finish')"
        :rounded="true"
        :inverted="true"
        icon-left="check"
        @click="finishMortgageApplication"
      ></b-button>

      <b-button
        v-else
        id="flowResultButtonFinishApplication"
        type="is-success"
        size="is-large"
        :label="$t('af:application.result.button.finish')"
        :rounded="true"
        :inverted="true"
        icon-left="check"
        @click="finishApplication"
      ></b-button>
    </b-field>
  </div>
</template>

<script>
import flowResultMixin from "../../mixins/flowResultMixin";

export default {
  name: "ApplicationFlowResult",
  mixins: [flowResultMixin],
  components: {
    ApplicationTemplate: () => import("@/components/ApplicationTemplate.vue"),
  },
  methods: {
    track() {
      if (this.isSuccess) {
        this.$gtag.event("closed_success");
      } else {
        this.$gtag.event("closed_fail");
      }
    },
  },
  mounted() {
    this.track();
  },
  computed: {
    isMortgage() {
      return !!this.$route.meta.mortgage;
    },
  },
};
</script>

<style scoped lang="scss">
.mbh {
  .result-title {
    font-size: 48px !important;
    font-weight: 800;
    line-height: 56px;
    color: #212121;
    margin-bottom: 32px !important;
  }

  .result-description {
    color: #051c3a;
    font-size: 16px;
  }

  .result-card {
    padding: 32px;
    border-radius: 24px;
    background: white;
  }
}
</style>
